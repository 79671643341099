<template>
  <div>
    <table>
      <thead>
        <th>User</th>
        <th v-for="index in questions.length" :key="index" v-text="`#${index}`"/>
      </thead>
      <tbody>
        <tr v-for="name in Object.keys(results)" :key="name">
          <td >
            <span :class="{'text-slate-300': !results[name].connected}">
              {{name}}
            </span>
          </td>
          <td v-for="index in questions.length" :key="index" class="text-center">
            <template v-if="results[name][index - 1]">
              <template v-if="results[name][index - 1].isCorrect">
                ✅
              </template>
              <template v-else>
                ❌
              </template>
            </template>
          </td>
        </tr>
        <tr>
          <td> Totals </td>
          <td v-for="(total, index) in totals" :key="total + index" class="text-center">
            <span :class="{
              'text-green-600': total === connectedAmount,
              'text-rose-600': total !== connectedAmount
            }">
              {{total}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import useMessenger from '@/composables/useMessenger'
import { computed } from 'vue'
import questions from '@/data/questions.json'
const { results } = useMessenger()

const connectedAmount = computed(() => Object.keys(results.value).filter(key => results.value[key].connected).length)
const names = computed(() => Object.keys(results.value))

const totals = computed(() => {
  const totals = new Array(questions.length).fill(0)

 for (let i = 0; i < questions.length; i++) {
    for (let j = 0; j < names.value.length; j++) {
      if (results.value[names.value[j]][i]) {
        totals[i]++
      }
    }
  }

  return totals
})

</script>
<style>
table, td, th {
  border: 1px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
}
</style>
