<template>
  <div class="bg-[#F1EDE7] p-8 font-cookie flex flex-col h-screen overflow-y-auto text-5xl gap-2 results relative">
    <div
        class="flex relative z-10"
        v-for="el in statistics"
        :key="el.name"
    >
      <div class="w-[300px]">
        {{el.name}}
      </div>
      <div class="grow">
        <div
            class="bg-[#A96245] h-full test text-white text-center overflow-hidden"
            :style="[ runStatistics ? { 'width': el.score + '%' } : {} ]"
        >
          {{el.score}}%
        </div>
      </div>
    </div>
    <img class="absolute w-1/5 right-0 bottom-0 z-[0]" src="../../../public/results.png" />
  </div>
</template>
<script setup>
import useMessenger from '@/composables/useMessenger'
import {computed, ref} from "vue";

const { results, userCorrectStatistics, getName } = useMessenger()
const runStatistics = ref(false)

const statistics = computed(() => {
  const els = [],
        keys = Object.keys(results.value)

  for (let i = 0; i < keys.length; i++) {
    const result = keys[i]
    const score = Math.trunc(userCorrectStatistics(result))

    if (els.length && score > els[i - 1].score) {
      const t = els[i - 1]
      els[i - 1] = {
        name: getName(result),
        score
      }
      els.push(t)
    } else {
      els.push({
        name: getName(result),
        score
      })
    }
  }

  return els
})

setTimeout(() => {
  runStatistics.value = true
}, 1000)

</script>
<style>
.test {
  width: 0%;
  transition: width 1s ease-in-out;
}
</style>
