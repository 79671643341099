<template>
  <div class="bg-red font-sen">
    <ResultsTable />
  </div>
</template>

<script setup>
import users from '@/data/names.json'
import ResultsTable from '@/src/components/ResultsTable'
import {onMounted} from 'vue'
import useMessenger from '@/composables/useMessenger'
const { addUser, addAnswer, results } = useMessenger()
for (const name of users) {
  addUser(name.key)
}

if (localStorage.getItem('admin')) {
  results.value = JSON.parse(localStorage.getItem('admin'))
  Object.keys(results.value).forEach(el => {
    if (results.value[el].length) {
      results.value[el].connected = true
    }
  })
}

onMounted(() => {
  let recaptchaScript = document.createElement('script')
  recaptchaScript.setAttribute('src', 'https://cdn.ably.com/lib/ably.min-1.js')
  recaptchaScript.onload = () => {
    // eslint-disable-next-line no-undef
    const realtime = Ably.Realtime.Promise({ authUrl: `/auth/?client=admin` })
    const channel = realtime.channels.get("game")
    channel.subscribe('greeting', (msg) => {
      addUser(msg.clientId, true)
    })
    channel.subscribe('answer', (msg) => {
      addAnswer(msg.clientId, msg.data.question, msg.data.answer)
      localStorage.setItem('admin', JSON.stringify(results.value))
    });

    document.addEventListener('keydown', event => {
      if (event.code === 'Space') {
        const adminChannel = realtime.channels.get("admin")
        adminChannel.publish('next-slide', null);
      }
    })
  }
  document.head.appendChild(recaptchaScript)
})

</script>
