<template>
  <div class=" flex flex-col h-screen bg-[#F3EEE8]"
       :class="{
          'pt-[70px]': counter >= 0
       }"
  >
    <AppHeader v-if="counter >= 0" :counter="counter + 1" admin />
    <div v-if="counter >= 0" class="relative content p-2 bg-[#FFFBF6] grow flex flex-wrap gap-2 justify-around max-h-full">
      <div class="absolute flex gap-4 top-1 bottom-1 w-full justify-center" :class="{
        'slideLeft justify-between': enableAnimation
      }">
        <img :src="`images/${questions[counter].src}`"
             class="h-full"
             :class="{
              'pullUp': !enableAnimation
           }"
        />
        <div class="text-[10rem] grow text-center"
             v-if="!startAgain"
             v-show="score >= 0"
             :class="{
              'pulse': scoreAnimation,
              'text-green-500': score > 0,
              'text-red-500': score === 0,
             }"
        >
          {{ score }}%
        </div>
        <div class="grow text-center"
             v-if="startAgain"
        >
          <div class="text-green-500 text-[10rem]"
               :class="{
                'pulse': scoreAnimation
                }">
            {{ getName(questions[counter].answer) }}
            <div :class="{
              'text-red-500': getCorrectPercentage(counter) === 0
            }">{{ getCorrectPercentage(counter) }}%</div>
          </div>
          <div class="slideRight text-red-500 text-5xl" v-if="otherNames.length">
            <template v-if="otherNames.length">Other guesses:</template>
            <div v-for="name in otherNames" v-text="name" :key="name" />
          </div>
        </div>
      </div>
    </div>
    <img v-else src="/plakat.png" class="max-h-full object-contain" />
  </div>
</template>
<script setup>
import AppHeader from '@/src/components/AppHeader'
import { onMounted, ref } from 'vue'
import questions from '@/data/questions'
import useMessenger from '@/composables/useMessenger'
import router from "@/src/router";

const counter = ref(-1)

const enableAnimation = ref(false)
const scoreAnimation = ref(false)
const startAgain = ref(false)

const score = ref(-1)

const { addAnswer, addUser, getCorrectPercentage, howWasCalled, getName, results } = useMessenger()

onMounted(() => {
  let recaptchaScript = document.createElement('script')
  recaptchaScript.setAttribute('src', 'https://cdn.ably.com/lib/ably.min-1.js')
  let channel
  recaptchaScript.onload = () => {
    // eslint-disable-next-line no-undef
    const realtime = Ably.Realtime.Promise({ authUrl: `/auth/?client=admin` })
    channel = realtime.channels.get("game")
    channel.subscribe('greeting', (msg) => {
      addUser(msg.clientId, true)
    })
    channel.subscribe('answer', (msg) => {
      addAnswer(msg.clientId, msg.data.question, msg.data.answer)
    });

    const adminChannel = realtime.channels.get('admin')
    adminChannel.subscribe('next-slide', () => {
      nextSlide()
    });

    let info = localStorage.getItem('play')
    if (info) {
      info = JSON.parse(info)
      counter.value = parseInt(JSON.parse(info).question)
      results.value = info.results
      channel.publish('question', counter.value + '')
    }
  }
  document.head.appendChild(recaptchaScript)

  const nextSlide = () => {
    if (counter.value >= questions.length - 1 && enableAnimation.value) {
      counter.value = 0
      startAgain.value = true
      enableAnimation.value = false
      score.value = -1
      otherNames.value = []
      setTimeout(() => {
        otherNames.value = howWasCalled(questions[counter.value].answer, counter.value)
      }, 1000)
      return
    }

    if (startAgain.value && counter.value < questions.length - 1) {
      counter.value ++
      otherNames.value = []
      setTimeout(() => {
        otherNames.value = howWasCalled(questions[counter.value].answer, counter.value)
      }, 1000)
      return
    }

    if (startAgain.value && counter.value === questions.length - 1) {
      router.push({ path: '/results' })
    }

    if (enableAnimation.value || counter.value < 0) {
      enableAnimation.value = false
      score.value = -1
      scoreAnimation.value = false
      counter.value ++
      channel.publish('question', counter.value + '')
      localStorage.setItem('play', JSON.stringify({
        question: counter.value,
        results: results.value
      }))
    } else {
      let targetValue = getCorrectPercentage(counter.value)
      setTimeout(() => {
        const interval = setInterval(() => {
          score.value += 1
          if (score.value >= targetValue) {
            clearInterval(interval)
            scoreAnimation.value = true
          }
        }, 1500 / targetValue)
      }, 1000)

      enableAnimation.value = true
    }
  }

  document.addEventListener('keydown', event => {
    if (event.code === 'Space') {
      nextSlide()
    }
  })
})

const otherNames = ref([])
</script>

