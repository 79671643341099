import { ref } from 'vue'
import users from '@/data/names.json'
let channel = null
const user = ref(null)
const results = ref({})
import questions from '@/data/questions.json'

export default () => {
  const addUser = (name, connected = false) => {
    if (!results.value[name]) {
      results.value[name] = []
    }
    results.value[name].connected = connected
  }

  const addAnswer = (name, question, answer) => {
    results.value[name][question] = {
      answer,
      isCorrect: questions[question].answer.includes(answer)
    }
  }

  const createChannel = (name) => {
    user.value = name
    // eslint-disable-next-line no-undef
    const realtime = Ably.Realtime.Promise({ authUrl: `/auth/?client=${user.value}` })
    channel = realtime.channels.get("game")
    channel.publish('greeting', null)

    return channel
  }

  const publish = (message, counter) => {
    channel.publish('answer', {
      question: counter,
      answer: message
    })
  }

  const getCorrectPercentage = questionNum => {
    const names = Object.keys(results.value)
    let counter = 0
    names.forEach(name => {
      if (results.value[name][questionNum] && results.value[name][questionNum].isCorrect) {
        counter ++
      }
    })

    return Math.ceil(counter * 100 / (names.length || 1))
  }

  const getName = answer => {
    if(Array.isArray(answer)) {
      let r = ''
      for(let t of answer) {
        if (r) {
          r += ' & '
        }
        r += users.find(({ key }) => key === t).title
      }
      return r
    }

    return users.find(({ key }) => key === answer).title
  }

  const  howWasCalled = (answer, num) => {
    const names = Object.keys(results.value)
    const set = new Set()
    names.forEach(name => {
      if (results.value[name][num]) {
        set.add(results.value[name][num].answer)
      }
    })

    return [ ...set ].filter(name => !answer.includes(name) ).map(name => getName(name))
  }

  const userCorrectStatistics = name => {
    const counter = results.value[name].reduce((acc, el) => {
      if (el && el.isCorrect) {
        acc ++
      }

      return acc
    }, 0)

    return counter * 100 / questions.length
  }

  return {
    createChannel,
    publish,
    user,
    addUser,
    results,
    addAnswer,
    getCorrectPercentage,
    howWasCalled,
    getName,
    userCorrectStatistics
  }
}
